import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import './cart.scss'
import { useState } from 'react';
import { Modal } from "react-bootstrap";
import ChildOfId from '../../hooks/dataFetchers/childIdOf'
import DomainMintingPrice from '../../hooks/dataSenders/mintPrice';
import useWeb3 from '../../hooks/useWeb3';
import DomainMinting from '../../hooks/dataSenders/mintDomainBulk';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import Allowance from '../../hooks/dataFetchers/allowance';
import Environment from '../../utils/Environment';
import Approve from '../../hooks/dataSenders/approveTomi';
import BalanceOf from '../../hooks/dataFetchers/balanceOf';
function Cart({ setCartState, cartState, setLoader, setCartState2, cartState2 }) {
    const [cart, setCart] = useState(false);
    const { account } = useWeb3React()
    const handleCloseCart = () => setCart(false);
    const handleShowCart = () => setCart(true);
    const [cart1, setCart1] = useState(false);
    const handleCloseCart1 = () => setCart1(false);
    const handleShowCart1 = () => setCart1(true);
    const [cart2, setCart2] = useState(false);
    const handleCloseCart2 = () => setCart2(false);
    const handleShowCart2 = () => setCart2(true);
    const [cart3, setCart3] = useState(false);
    const handleCloseCart3 = () => setCart3(false);
    const handleShowCart3 = () => setCart3(true);
    const [cartArray, setCartArr] = useState([])
    const { checkAllowance } = Allowance()
    const { UserApprove } = Approve()
    const history = useHistory()
    const [mintPrice, setMintPrice] = useState()
    const { domainMintingMethod } = DomainMinting()
    const [gasFee, setGasFee] = useState(0)
    const [domainsAvail, setDomainsAvail] = useState([])
    const web3 = useWeb3();
    const { domainMintingPriceMethod } = DomainMintingPrice()
    const { Mainfunct } = ChildOfId();
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [routes, setRoutes] = useState(false);
    const [balance, setBalance] = useState(true)
    const { balanceOfHook } = BalanceOf()
    const availableItemsCount = cartArray?.filter((item, index) => domainsAvail[index])?.length;
    let toastDesign = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    const mintPriceFun = async (name, type) => {
        const res = await domainMintingPriceMethod(name, type)
        // // console.log('dlfjalsfjl', res)
        setMintPrice(res)
    }
    const suggestedDomains = async () => {

        try {
            setLoader(true);

            const requests = cartArray?.map((item, id) =>
                Promise.all([
                    Mainfunct(item?.name, item?.tld)
                ])
            );
            setLoader(true);
            const results = await Promise.all(requests);

            const dumArray = results.map(([res]) => res);
            setDomainsAvail(dumArray);
            setLoader(false);
            return dumArray
        } catch (error) {
            // console.error("Error occurred:", error);
            setLoader(false);
        } finally {

        }
    };
    const removeItem = async (param) => {
        let res = cartArray?.filter((item) => {
            return (`${item?.name}${item?.tld}` !== `${param?.name}${param?.tld}`)
        })
        localStorage.setItem('cart', JSON.stringify(res));
        if (cartState !== undefined) {
            setCartState(!cartState);
        }

        setCartState2(!cartState2);
        // console.log('filter', res)
    }
    const clearAll = () => {
        localStorage.removeItem('cart');
        if (cartState !== undefined) {
            setCartState(!cartState);
        }
        setCartState2(!cartState2);
    }
    const gasPriceFun = () => {

        web3.eth.getGasPrice().then((result) => {
            var result2 = parseInt(result) + 3000000000
            // result2 = web3.utils.fromWei(result2?.toString(), 'ether')
            // console.log("gasfun", result2)
            setGasFee(result2)
            // gasFunPrice = result2.toString()
        })
    }
    const completePurchase = async () => {
        try {
            setLoader(true);

            const domainAvailArray = await suggestedDomains();
            if (!domainAvailArray || domainAvailArray.length === 0) {
                setLoader(false);
                toast.info('No Domain Is Available For Minting!', toastDesign);
                return;
            }

            const filteredCartArray = cartArray.filter((item, index) => domainAvailArray[index]);
            if (filteredCartArray.length === 0) {
                setLoader(false);
                toast.info('No Available Domains in the Cart!', toastDesign);
                return;
            }
            setLoader(true);
            const allowanceRes = await checkAllowance(account, Environment.ethRegistrarBulk);
            const requiredAllowance = parseFloat(availableItemsCount * mintPrice) || 0;
            const isAllowanceSufficient = parseFloat(web3.utils.fromWei(allowanceRes.toString(), 'ether')) >= requiredAllowance;

            if (isAllowanceSufficient) {
                await domainMintingMethod(filteredCartArray);
            } else {
                const res = await UserApprove(Environment.ethRegistrarBulk);
                if (!res) {
                    setLoader(false);
                    handleCloseCart();
                    handleShowCart3();
                    return;
                }
                await domainMintingMethod(filteredCartArray);
            }

            handleCloseCart();
            handleShowCart1();
            setLoader(false);
            localStorage.removeItem('cart');
        } catch (error) {
            setLoader(false);
            handleCloseCart();
            handleShowCart3();
            // toast.error('Minting Failed', toastDesign);
        }
    };

    const allowanceofAccount = async () => {
        let res = await balanceOfHook(account)
        // console.log("ressss", res, mintPrice)
        setBalance(res)

        // if (parseFloat(res) <= parseFloat(cartArray?.length * mintPrice) || 0) {
        //   handleShow2()
        // } else {
        // }
    }

    useEffect(() => {
        let cartArr = localStorage.getItem('cart')
        cartArr = JSON.parse(cartArr)
        setCartArr(cartArr)
    }, [cartState !== undefined && cartState, cartState2])
    useEffect(() => {
        if (cartArray?.length > 0) {
            suggestedDomains()
            mintPriceFun(cartArray[0]?.name, cartArray[0]?.tld)
        }
    }, [cartArray])
    useEffect(() => {
        if (mintPrice && account) {
            allowanceofAccount()
        }
    }, [mintPrice, account])
    return (
        <>
            <div className='cartMain'>
                <div className="cart-parent">
                    <button onClick={() => { handleShowCart(); mintPriceFun('info', 'tomi'); suggestedDomains(); gasPriceFun() }} className="cart-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M18.19 17.75H7.53999C6.54999 17.75 5.59999 17.33 4.92999 16.6C4.25999 15.87 3.92 14.89 4 13.9L4.83 3.94C4.86 3.63 4.74999 3.33001 4.53999 3.10001C4.32999 2.87001 4.04 2.75 3.73 2.75H2C1.59 2.75 1.25 2.41 1.25 2C1.25 1.59 1.59 1.25 2 1.25H3.74001C4.47001 1.25 5.15999 1.56 5.64999 2.09C5.91999 2.39 6.12 2.74 6.23 3.13H18.72C19.73 3.13 20.66 3.53 21.34 4.25C22.01 4.98 22.35 5.93 22.27 6.94L21.73 14.44C21.62 16.27 20.02 17.75 18.19 17.75ZM6.28 4.62L5.5 14.02C5.45 14.6 5.64 15.15 6.03 15.58C6.42 16.01 6.95999 16.24 7.53999 16.24H18.19C19.23 16.24 20.17 15.36 20.25 14.32L20.79 6.82001C20.83 6.23001 20.64 5.67001 20.25 5.26001C19.86 4.84001 19.32 4.60999 18.73 4.60999H6.28V4.62Z" fill="#FF0083"/>
  <path d="M16.25 22.75C15.15 22.75 14.25 21.85 14.25 20.75C14.25 19.65 15.15 18.75 16.25 18.75C17.35 18.75 18.25 19.65 18.25 20.75C18.25 21.85 17.35 22.75 16.25 22.75ZM16.25 20.25C15.97 20.25 15.75 20.47 15.75 20.75C15.75 21.03 15.97 21.25 16.25 21.25C16.53 21.25 16.75 21.03 16.75 20.75C16.75 20.47 16.53 20.25 16.25 20.25Z" fill="#FF0083"/>
  <path d="M8.25 22.75C7.15 22.75 6.25 21.85 6.25 20.75C6.25 19.65 7.15 18.75 8.25 18.75C9.35 18.75 10.25 19.65 10.25 20.75C10.25 21.85 9.35 22.75 8.25 22.75ZM8.25 20.25C7.97 20.25 7.75 20.47 7.75 20.75C7.75 21.03 7.97 21.25 8.25 21.25C8.53 21.25 8.75 21.03 8.75 20.75C8.75 20.47 8.53 20.25 8.25 20.25Z" fill="#FF0083"/>
  <path d="M21 8.75H9C8.59 8.75 8.25 8.41 8.25 8C8.25 7.59 8.59 7.25 9 7.25H21C21.41 7.25 21.75 7.59 21.75 8C21.75 8.41 21.41 8.75 21 8.75Z" fill="#FF0083"/>
</svg>
                    </button>
                    {cartArray?.length > 0 ? <span>{cartArray?.length}</span> : ''}
                </div>

                {/* <button onClick={handleShowCart1}>one</button>
                <button onClick={handleShowCart2}>two</button>
                <button onClick={handleShowCart3}>three</button> */}
            </div>


            {/* All Cart modal /here.................................. */}
            <Modal className="cart-modal" show={cart} onHide={handleCloseCart} centered>
                <Modal.Header closeButton >
                    <Modal.Title> {cartArray?.length > 0 ? 'Cart' : 'Cart is Empty'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="cart-main-modal">
                        <div className="inner-first">
                            <h6>Domains <span>{cartArray?.length || 0}</span></h6>
                            {cartArray?.length > 0 && <a href="#" onClick={clearAll}>Clear All</a>}
                        </div>
                        <div className="cart-items">
                            {cartArray?.map((item, id) => {
                                let avail = domainsAvail[id]
                                return (
                                    <div className="cart-item">
                                        <div className="left">
                                            <div className="inner-left">
                                                <div className="domain-set-img">
                                                    <img src="\assets\Domaincard.svg" alt="img" className="img-fluid domain-img" />
                                                    <p>{item?.name?.substring(0, 10)}{item?.name?.length > 10 && '(...)'}.{item?.tld}</p>
                                                </div>
                                            </div>
                                            <div className="inner-right">
                                                <h6>{item?.name?.substring(0, 10)}{item?.name?.length > 10 && '(...)'}.{item?.tld}</h6>
                                                {avail ? <p className="available">Available</p> : <p className="available cartUnAvail ">UnAvailable</p>}
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div onClick={() => removeItem(item)} className="close-icon">
                                                <img src="\cart\close-icon.svg" alt="img" className="img-fluid" />
                                            </div>
                                            <div className="inner-text">
                                                <p>Price</p>
                                                <h6>{parseFloat(mintPrice)?.toFixed(2)} TOMI</h6>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>

                        <div className="total-fee">
                            <div className="inner-text">
                                <p>Gas fee</p>
                                <h6>{web3.utils.fromWei(((availableItemsCount * gasFee) || 0)?.toString(), 'ether')} ETH</h6>
                            </div>
                            <div className="inner-text">
                                <p>Subtotal</p>
                                <h6>{((availableItemsCount * mintPrice) || 0)?.toFixed(2)} TOMI</h6>
                            </div>
                        </div>
                        {availableItemsCount > 0 &&
                            <div className="bottom-btn">
                                {/* ; */}
                                {account ? (parseFloat(balance) < parseFloat(mintPrice) ? <button className="btn-pink" onClick={handleShow2} >Buy Tomi</button> :
                                    <button className="btn-pink" onClick={() => {
                                        completePurchase()
                                    }}>Complete Purchase</button>) : <button data-toggle="modal"
                                        data-target="#exampleModalwallet" className="btn-pink" >Connect Wallet</button>}
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>


            <Modal className="cart-modal" show={cart1} centered>
                <Modal.Header onClick={() => { clearAll(); history.push('/') }} closeButton onHide={handleCloseCart1}>
                    <Modal.Title>Purchase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="purchase-main-content">
                        <div className="top-heading">
                            <h6>Your domains have been minted successfully</h6>
                            <p>And placed on the marketplace for auction until 20th June, 2024</p>
                        </div>
                        <div className="inner-mid">
                            {cartArray?.map((item, id) => {
                                let avail = domainsAvail[id]
                                return (
                                    <>
                                        {avail &&
                                            <div className="inner-cart">
                                                <div className="inner-left">
                                                    <div className="domain-set-img">
                                                        <img src="\cart\domain-bg-img.svg" alt="img" className="img-fluid domain-img" />
                                                        <p>{item?.name?.substring(0, 10)}{item?.name?.length > 10 && '(...)'}.{item?.tld}</p>
                                                    </div>
                                                    <h6>{item?.name?.substring(0, 10)}{item?.name?.length > 10 && '(...)'}.{item?.tld}</h6>
                                                </div>
                                                <div className="inner-right">
                                                    <p>Price</p>
                                                    <h6>{parseFloat(mintPrice)?.toFixed(2)} TOMI</h6>
                                                </div>
                                            </div>}</>
                                )
                            })}
                        </div>
                        <div className="total-fee">
                            <div className="inner-text">
                                <p>Total Price</p>
                                <h6>{availableItemsCount || 0} items</h6>
                            </div>
                            <div className="inner-text">
                                <p>{((availableItemsCount * mintPrice) || 0)?.toFixed(2)} TOMI</p>
                                {/* <h6>~3,662.084 USD</h6> */}
                            </div>
                        </div>
                        <div className="bottom-btn">
                            <button className="btn-pink" onClick={() => {
                                clearAll(); history.push('/marketplace')
                            }}>Go Checkout My Domain</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="cart-modal" show={cart2} onHide={handleCloseCart2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Purchase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="purchase-main-content">
                        <div className="top-heading">
                            <h6>Your purchase was partially completed</h6>
                        </div>
                        <div className="ifitem-failed">
                            <img src="\cart\info-circle.svg" alt="img" className="img-fluid" />
                            <div className="inner-text">
                                <h6>1 item failed</h6>
                                <p>Purchase can fail due to network issues, gas fee increases, or because someone else bought the item before you.</p>
                            </div>
                        </div>
                        <div className="inner-mid">
                            <div className="inner-cart">
                                <div className="inner-left">
                                    <div className="domain-set-img">
                                        <img src="\cart\domain-bg-img.svg" alt="img" className="img-fluid domain-img" />
                                        <p>world.tomi</p>
                                    </div>
                                    <h6>world.tomi</h6>
                                </div>
                                <div className="inner-right">
                                    <p>Price</p>
                                    <h6>24.99 TOMI</h6>
                                </div>
                            </div>
                            <div className="inner-cart">
                                <div className="inner-left">
                                    <div className="domain-set-img">
                                        <img src="\cart\domain-bg-img.svg" alt="img" className="img-fluid domain-img" />
                                        <p>world.tomi</p>
                                    </div>
                                    <h6>world.tomi</h6>
                                </div>
                                <div className="inner-right">
                                    <p>Price</p>
                                    <h6>24.99 TOMI</h6>
                                </div>
                            </div>
                        </div>
                        <div className="total-fee">
                            <div className="inner-text">
                                <p>Total Price</p>
                                <h6>2 items</h6>
                            </div>
                            <div className="inner-text">
                                <p>1,024.99 TOMI</p>
                                <h6>~3,662.084 USD</h6>
                            </div>
                        </div>
                        <div className="bottom-btn">
                            <button className="btn-pink" onClick={() => {
                                handleCloseCart2();
                                handleShowCart3();
                            }}>Go Checkout My Domain</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal className="cart-modal" show={cart3} onHide={handleCloseCart3} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Purchase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="purchase-main-content">
                        <div className="top-heading">
                            <h6>Your purchase failed</h6>
                        </div>
                        <div className="ifitem-failed">
                            <img src="\cart\info-circle.svg" alt="img" className="img-fluid" />
                            <div className="inner-text">
                                <h6>{availableItemsCount || 0} items failed</h6>
                                <p>Purchase can fail due to network issues, gas fee increases, or because someone else bought the item before you.</p>
                            </div>
                        </div>
                        <div className="total-fee">
                            <div className="inner-text">
                                <p>Total Price</p>
                                <h6>{availableItemsCount || 0} items</h6>
                            </div>
                            <div className="inner-text">
                                <p>{((availableItemsCount * mintPrice) || 0)?.toFixed(2)} TOMI</p>
                                {/* <h6>~3,662.084 USD</h6> */}
                            </div>
                        </div>
                        <div className="bottom-btn">
                            <button className="btn-pink" onClick={() => {
                                handleCloseCart3();
                            }}>Done</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='oops-modal' show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton >
                    <img style={{ cursor: "pointer" }} onClick={() => { setRoutes(!routes) }} src="\assets\newlanding-assets\back-btn.svg" alt="img" className={routes ? 'img-fluid back-btn' : "d-none"} />
                    <Modal.Title>Buy tomi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !routes ?
                            <div className={!routes ? "buytomi-modaldiv slide-left" : "buytomi-modaldiv"}>
                                <div className="upper-img">
                                    <img src="\assets\newlanding-assets\buytomi.svg" alt="img" className='img-fluid' />
                                </div>
                                <p className="para">It appears that you don’t have any TOMI tokens in your wallet. In order to mint tDNS domains, you will require TOMI tokens</p>
                                <button onClick={() => setRoutes(!routes)} className='buytomi-btn'>Buy TOMI</button>
                            </div> : <>
                                <div className={routes ? "another-div slide-right" : "another-div"}>
                                    <div className="parent-items">
                                        <a href="https://www.kucoin.com/trade/TOMI-USDT" target='_blank'>
                                            <div className="inner-item">
                                                <img src="\assets\newlanding-assets\exchangesicons\kucoin.svg" alt="img" className='img-fluid' />
                                                <h6>KuCoin</h6>
                                            </div>
                                        </a>
                                        <a href="https://www.bybit.com/en-US/trade/spot/TOMI/USDT" target='_blank'>
                                            <div className="inner-item">
                                                <img src="\assets\newlanding-assets\exchangesicons\bybit.svg" alt="img" className='img-fluid' />
                                                <h6>ByBit</h6>
                                            </div>
                                        </a>
                                        <a href="https://www.huobi.com/en-us/trade/tomi_usdt" target='_blank'>
                                            <div className="inner-item">
                                                <img src="\assets\newlanding-assets\exchangesicons\huobi.svg" alt="img" className='img-fluid' />
                                                <h6>Huobi</h6>
                                            </div>
                                        </a>
                                        <a href="https://www.gate.io/trade/TOMI_USDT" target='_blank'>
                                            <div className="inner-item">
                                                <img src="\assets\newlanding-assets\exchangesicons\gateio.svg" alt="img" className='img-fluid' />
                                                <h6>Gate.io</h6>
                                            </div>
                                        </a>
                                        <a href="https://www.mexc.com/exchange/TOMI_USDT" target='_blank'>
                                            <div className="inner-item">
                                                <img src="\assets\newlanding-assets\exchangesicons\mexc.svg" alt="img" className='img-fluid' />
                                                <h6>Mexc</h6>
                                            </div>
                                        </a>
                                        <a href="https://crypto.com/exchange/trade/TOMI_USD" target='_blank'>
                                            <div className="inner-item">
                                                <img src="\assets\newlanding-assets\exchangesicons\crypto.svg" alt="img" className='img-fluid' />
                                                <h6>Crypto.com</h6>
                                            </div>
                                        </a>
                                        <a href="https://www.bitget.com/spot/TOMIUSDT?type=spot" target='_blank'>
                                            <div className="inner-item">
                                                <img src="\assets\newlanding-assets\exchangesicons\bitget.svg" alt="img" className='img-fluid' />
                                                <h6>Bitget</h6>
                                            </div>
                                        </a>
                                        <a href="https://phemex.com/spot/trade/TOMIUSDT" target='_blank'>
                                            <div className="inner-item">
                                                <img src="\assets\newlanding-assets\exchangesicons\phemex.svg" alt="img" className='img-fluid' />
                                                <h6>Phemex</h6>
                                            </div>
                                        </a>
                                    </div>
                                    <img src="\assets\newlanding-assets\or-img.svg" alt="img" className='img-fluid para-or' />
                                    <div className="twice-btns">
                                        <a href="https://app.uniswap.org/#/swap?outputCurrency=0x4385328cc4d643ca98dfea734360c0f596c83449" target='_blank'>    <button className='uni-swap'> <img src="\assets\newlanding-assets\uniswap.svg" alt="img" className='img-fluid mr-2' /> Buy on Uniswap</button></a>
                                        <a href="https://changelly.com/" target='_blank'>    <button className='btn-chan'> <img src="\assets\newlanding-assets\changely.svg" alt="img" className='img-fluid mr-2' /> Buy on Changelly</button></a>
                                    </div>
                                </div>
                            </>
                    }

                </Modal.Body>
            </Modal>
        </>
    )
}

export default Cart