import React, { useState } from "react";
import "./threebanners.scss";

const Threebanners = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("values", values);

    // Update the corresponding field in state
    setValues({
      ...values, // Keep the other fields intact
      [name]: value, // Update the specific field being changed
    });
  };

  const Connect = () => {
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "generate_lead",
        event_label: "early_access",
      });
      console.log("cartttttt1111", window.dataLayer);
      console.log(`GTM event 'generate_lead' triggered `);
    } else {
      console.error("Google Tag Manager dataLayer is not available");
    }
  };
  return (
    <>
      <section className="befirst">
        <img src="\assets\secelispse.svg" alt="" className="elipse img-fluid" />
        <div className="custom-container">
          <div className="section_one">
            <div>
              <img width={436} height={275} src="/assets/nftdomain.png" alt="NFT Domain" className="img-fluid" />
            </div>
            <div>
              <p>//NFT ownership</p>
              <h2>
                <span>Ownership You</span>
              </h2>
              <h2 className="whitetext">Can Prove</h2>
              <p className="whitepara">
                Since tomi domains are NFTs, you can prove ownership and claim rewards for eternity!
                NFT ownership is the key to building a new version of the internet that is truly
                distributed.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="buydomain">
        <img src="\assets\eslipse2.svg" alt="" className="elipse2" />
        <div className="custom-containerl">
          <div className="main_outer">
            <div className="innner_two">
              <p>//Decentralized deployment</p>
              <h2>Do it all on </h2>
              <h2>
                <span>tomi</span>
              </h2>
            </div>
            <div className="right_side">
              <div className="flexxdiv">
                <div className="innnner_div">
                  <div>
                    <img src="\assets\smallcard.svg" alt="" />
                    <div>
                      <p>//01</p>
                      <h3>Super VPN</h3>
                      <p className="lowerpara">
                        Protect your browsing journey with robust encryption and tomi’s no-logs
                        policy.
                      </p>
                    </div>
                  </div>
                  <div>
                    <img src="\assets\Browse.svg" alt="" />
                    <div>
                      <p>//02</p>
                      <h3>Explore, Share, Create</h3>
                      <p className="lowerpara">
                        Uncensored video sharing offers an open space for self-expression,
                        creativity, and education. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="innnner_div">
                  <div>
                    <img src="\assets\Cookies.svg" alt="" />
                    <div>
                      <p>//03</p>
                      <h3>Cut the Cookies</h3>
                      <p className="lowerpara">
                        Cookies don’t follow you around and track your every move while you surf.
                      </p>
                    </div>
                  </div>
                  <div>
                    <img src="\assets\RSS.svg" alt="" />
                    <div>
                      <p>//04</p>
                      <h3>News Feed</h3>
                      <p className="lowerpara">
                        Customize your daily news to fit your interests without subjecting yourself
                        to data mining and ad snooping.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="innnner_div">
                  <div>
                    <img src="\assets\No ads.svg" alt="" />
                    <div>
                      <p>//05</p>
                      <h3>Poof!</h3>
                      <p className="lowerpara">
                        Annoying Ads and cookies don’t exist on tomi. (we block the ones you don’t
                        find particularly annoying too.)
                      </p>
                    </div>
                  </div>
                  <div>
                    <img src="\assets\Plane.svg" alt="" />
                    <div>
                      <p>//06</p>
                      <h3>Listen Offline</h3>
                      <p className="lowerpara">
                        Tune into your favorite songs and catch the latest podcast episode. No
                        internet connection needed! 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="rewardeveryone">
        <img src="\assets\circle.svg" alt="" className="elipse3 img-fluid" />
        <div className="custom-containerl">
          <div className="mainsection_three">
            <div className="left_side">
              <p>//Contact Us</p>
              <h2 className="whitetext">
                Get<span> Early Access</span>
              </h2>
              <p className="whitepara">
                Sign up to get exciting new tomi features as soon as they're ready for use!
              </p>
            </div>
            <div className="input_type">
              <div className="typpppe">
                <input
                  placeholder="Enter Name"
                  type="text"
                  id="fname"
                  name="name"
                  value={values?.name}
                  onChange={handleChange}
                />
                <svg
                  className="poxxx"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 3C9.80271 3 8 4.80271 8 7C8 9.19729 9.80271 11 12 11C14.1973 11 16 9.19729 16 7C16 4.80271 14.1973 3 12 3ZM12 5C13.1164 5 14 5.88359 14 7C14 8.11641 13.1164 9 12 9C10.8836 9 10 8.11641 10 7C10 5.88359 10.8836 5 12 5ZM12 14C10.255 14 8.18716 14.4098 6.44922 15.0957C5.58025 15.4387 4.7947 15.846 4.16602 16.3691C3.53734 16.8922 3 17.5994 3 18.5V21H21V20V18.5C21 17.5994 20.4627 16.8922 19.834 16.3691C19.2053 15.846 18.4198 15.4387 17.5508 15.0957C15.8128 14.4098 13.745 14 12 14ZM12 16C13.414 16 15.3461 16.374 16.8184 16.9551C17.5545 17.2456 18.177 17.592 18.5547 17.9062C18.9324 18.2205 19 18.4346 19 18.5V19H5V18.5C5 18.4346 5.06759 18.2205 5.44531 17.9062C5.82304 17.592 6.44549 17.2456 7.18164 16.9551C8.65395 16.374 10.586 16 12 16Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="typpppe">
                <input
                  placeholder="Enter Email Address"
                  type="email"
                  id="fname"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <svg
                  className="poxxx"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 4C2.897 4 2 4.897 2 6V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V6C22 4.897 21.103 4 20 4H4ZM4 6H20V6.00586L12 11L4 6.00391V6ZM4 8.00391L12 13L20 8.00586L20.002 18H4V8.00391Z"
                    fill="white"
                  />
                </svg>
              </div>
              <button onClick={Connect}>Let’s Connect</button>
            </div>
          </div>
        </div>
      </section> */}

      <section className="refferprogram">
        <img src="\assets\newshade.svg" className="myyyelipse img-fluid" />
        <img src="\assets\newshade.svg" className="myyyelipse2 img-fluid" />
        <div className="custom-containerl">
          <div className="mainlast">
            <div className="innnnnner">
              <img src="\assets\Globe2.svg" alt="" className="img-fluid" />
              <h2>
                Own Your Identity on the Web: Claim <span> Your NFT Domain </span> on tomi
              </h2>
              <p>
                Claim your unique NFT domain name on tomi's secure and decentralized network. Enjoy
                greater privacy, control, and potential rewards!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Threebanners;
