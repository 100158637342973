import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/landing/header/Navbar.js';
import Footer from './components/landing/footer/Footer.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DSearchResult from './components/DomainSearchResult/DSearchResult';
import Watchlist from './components/watchlist/Watchlist';
import Account from './components/AccountSetting/Account';
import useEagerConnect from './hooks/useEagerConnect';
import Cart from './components/cart/Cart';
import Chekout from './components/chekout/Chekout';
import Signup from './components/SignUp_Login/Signup';
import Signup1 from './components/SignUp_Login/Signup1';
import Resetpassword from './components/SignUp_Login/Resetpassword';
import Success from './components/SignUp_Login/Success';
import { Signup2 } from './components/SignUp_Login/Signup2';
import Login from './components/SignUp_Login/Login';
import Billing from './components/Billing/Billing';
import DomainProfile from './components/DomainProfile/DomainProfile';
import OrderComplete from './components/chekout/orderComplete/OrderComplete';
import MintDomain from './components/mintdomain/MintDomain';
import DomainProfile1 from './components/DomainProfile/DomainProfile1';
import Market from './components/Marketplace/Market';
import Place from './components/Placebid/Place';
import AccountSetting1 from './components/AccountSetting/AccountSetting1';
import OfferRecieved from './components/Placebid/OfferRecieved';
import MyDomain from './components/DomainSearchResult/mydomain/MyDomain';
import ProgressBarComp from './components/DomainSearchResult/ProgressBarComp';
import ScrollMaster from './components/Marketplace/ScrollMaster';
import Mail from './components/PartnerEmail/Mail';
import Partner from './components/landing/Partner/Partner';
import Commission from './components/MyCommission/Commission';
import LandingNew from './components/LandingNew/LandingNew';
import Refferal from './components/RefferalProgram/Refferal';
import Settings from './components/RefferalProgram/Settings';
import CashBack from './components/MyCashback/CashBack';
import AutoSu from './components/AutoSu';
import Privacypolicy from './components/Privacypolicy/Privacypolicy.jsx';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';


function App() {
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);
  useEffect(() => {
    const isFirstVisit = localStorage.getItem("firstVisit");
    if (!isFirstVisit) {
      localStorage.setItem("firstVisit", "true");
      setTimeout(() => {
        handleShow11();
      }, 6000);
    }
  }, []);
  useEagerConnect()
  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        {/* <Navbar/> */}
        <Switch>
          <Route exact path='/ai' component={AutoSu} />
          <Route exact path='/' component={LandingNew} />
          <Route exact path='/domainSearhResult/:status/:SecondParam' component={DSearchResult} />
          <Route exact path='/watchlist' component={Watchlist} />
          <Route exact path='/accountsetting' component={Account} />
          <Route exact path='/cart' component={Cart} />
          <Route exact path='/checkout' component={Chekout} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/signup1' component={Signup1} />
          <Route exact path='/reset' component={Resetpassword} />
          <Route exact path='/success' component={Success} />
          <Route exact path='/signup2' component={Signup2} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/billing' component={Billing} />
          <Route exact path='/domainprofile' component={DomainProfile} />
          <Route exact path='/domainprofile1/:data' component={DomainProfile1} />
          <Route exact path='/orderComplete' component={OrderComplete} />
          <Route exact path='/mintDomain' component={MintDomain} />
          <Route exact path='/marketplace' component={Market} />
          <Route exact path='/place/:type' component={Place} />
          <Route exact path='/accounsetting1' component={AccountSetting1} />
          <Route exact path='/offerrecieved' component={OfferRecieved} />
          <Route exact path='/mydomian' component={MyDomain} />
          <Route exact path='/progressbar/:type/:inpuVal' component={ProgressBarComp} />
          <Route exact path='/mail' component={Mail} />
          <Route exact path='/partner' component={Partner} />
          <Route exact path='/commission' component={Commission} />
          <Route exact path='/cashback' component={CashBack} />
          <Route exact path='/refferalprogram' component={Refferal} />
          <Route exact path='/settings' component={Settings} />
          <Route exact path='/privacy' component={Privacypolicy} />
        </Switch>
        <Footer />
      </Router>

   
    </>
  );
}

export default App;
