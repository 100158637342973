import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../hooks/loader'
import Navbar from '../landing/header/Navbar'
import DomainSearch from './DomainSearch'
import './dsearchResult.scss'
import MyDomain from './mydomain/MyDomain'
import Transaction from './Transactions/Transaction'
import { useHistory } from 'react-router-dom'
function DSearchResult() {
    const [loader, setLoader] = useState(false);
    const [cartState, setCartState]=useState(false)
    const [minted, setMinted] = useState(false);
    const { history } = useHistory
    let { status, SecondParam } = useParams();
    // console.log('asdfasdf',status,SecondParam )
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
        }
    }, [])
    return (
        <>
            {loader && <Loader />}
            <Navbar setTab={''} setTab2={'common'} setTab3={''} setCartState={setCartState} cartState={cartState} />
            <div className="domain-search-result">
            <img src='\assets\newshade.svg' className='myyyelipse img-fluid' />
            <img src='\assets\BG.svg' className='myyyelipse2 img-fluid' />
                <div className="custom-container">
                {status === 'active' || <DomainSearch minted={minted} setMinted={setMinted} domainProp={status} domainName={SecondParam} setLoader={setLoader} setCartState={setCartState} cartState={cartState}/>}
              {!SecondParam || status === 'active'  ?   <MyDomain minted={minted} setLoader={setLoader} SecondParam={SecondParam} loader={loader} /> : ""}
                    {/* {status === 'active' || <div className="topNav-top">
                        <div className="row">
                        
                            <div className="col-lg-12 mx-auto px-md-0 p-0">
                                <ul class="nav nav-pills topNavs" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class={status === 'active' ? `nav-link ` : `nav-link active`} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Domain Search</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={status === 'active' ? `nav-link active` : `nav-link`} id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">My Domains</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>} */}
                    {/* <div className="row">
                        <div className="col-lg-12 mx-auto px-md-0 p-0">
                            <div class="tab-content" id="pills-tabContent">
                                <div class={status === 'active' ? `tab-pane fade ` : `tab-pane fade show active`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                   
                                </div>
                                <div class={status === 'active' ? `tab-pane fade active show` : `tab-pane fade`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <h2><MyDomain minted={minted} setLoader={setLoader} SecondParam={SecondParam} loader={loader} /></h2>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default DSearchResult